import { TableColumnConfigurable } from '../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../dto/table.dto';

export const associatedSlaListConfig: TableColumnConfigurable[] = [
  {
    type: DataCellType.Text,
    field: 'reference_number',
    header: 'Reference',
    sortable: true,
    size: DataCellSize.Title,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'title',
    header: 'Title',
    sortable: true,
    size: DataCellSize.Title,
    limit: 50,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Para,
    field: 'description',
    header: 'Description',
    sortable: true,
    size: DataCellSize.Para,
    limit: 50,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'sla_category',
    header: 'SLA Category',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'severity_level',
    header: 'Severity Level',
    sortable: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.User,
    field: 'responsible_user',
    header: 'Responsible User',
    sortable: true,
    size: DataCellSize.User,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'lifecycle_status',
    header: 'Status',
    sortable: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
 
];
