import { TableColumnConfigurable } from '../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../dto/table.dto';

export const associatedProcessListConfig: TableColumnConfigurable[] = [
  {
    type: DataCellType.Text,
    field: 'reference_number',
    header: 'Reference Number',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'process_group_title',
    header: 'Process Group',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'title',
    header: 'Title',
    sortable: true,
    size: DataCellSize.Title,
    limit: 50,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'responsible_unit_title',
    header: 'Responsible Unit',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.User,
    field: 'owner',
    header: 'Owner Name',
    sortable: true,
    size: DataCellSize.User,
    isEnable: true,
    isRequired: true,
  },

  {
    type: DataCellType.ColorLabel,
    field: 'status',
    header: 'Status',
    sortable: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
];
