import { TableColumnConfigurable } from "../../core/modals/table.modal";

export const associatedComplianceRequirementListConfig: TableColumnConfigurable[] = [
        {
            field: 'compliance_register',
            header: 'Document',
            sortable: true,
            size: 1,
            isEnable: true,
            isRequired: true,
            isRawSpan: true,
        },
        {
            field: 'compliance_requirement_title',
            header: 'Requirement',
            sortable: true,
            size: 2,
            isEnable: true,
            isRequired: true,
            isRawSpan: false,
        },
        
    ];

  