import { TableColumnConfigurable } from '../../core/modals/table.modal';
import { DataCellType } from '../dto/table.dto';

export const associatedStrategicObjectiveListConfig: TableColumnConfigurable[] =
  [
    {
      type: DataCellType.Text,
      field: 'strategic_objective_title',
      header: 'Title',
      sortable: true,
      size: 800,
      isEnable: true,
      isRequired: true,
    },
  ];
