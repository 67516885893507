import { TableColumnConfigurable } from '../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../dto/table.dto';

export const associatedIncidentListConfig: TableColumnConfigurable[] = [
  {
    type: DataCellType.Text,
    field: 'reference_number',
    header: 'Reference',
    sortable: true,
    size: DataCellSize.Title,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'title',
    header: 'Title',
    sortable: true,
    size: DataCellSize.Title,
    limit: 50,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'location',
    header: 'Location',
    sortable: true,
    size: DataCellSize.Title,
    limit: 50,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'incident_at',
    header: 'Incident At',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'incident_type',
    header: 'Incident Type',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'incident_category',
    header: 'Incident Category',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'severity_level',
    header: 'Severity Level',
    sortable: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'incident_status',
    header: 'Status',
    sortable: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
];
