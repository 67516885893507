import { TableColumnConfigurable } from '../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../dto/table.dto';

export const associatedRiskListConfig: TableColumnConfigurable[] = [
  {
    type: DataCellType.Text,
    field: 'reference_number',
    header: 'Reference Number',
    sortable: true,
    size: DataCellSize.Title,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'risk_category',
    header: 'Risk Category',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'title',
    header: 'Title',
    sortable: true,
    size: DataCellSize.Title,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Para,
    field: 'description',
    header: 'Risk Description',
    sortable: true,
    size: DataCellSize.Para,
    limit: 50,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'risk_source',
    header: 'Source',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'owner_unit',
    header: 'Owner Unit',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.User,
    field: 'owner',
    header: 'Owner',
    sortable: true,
    size: DataCellSize.User,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'risk_status',
    header: 'Status',
    sortable: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
];
